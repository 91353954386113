<template>
	<S2SForm title="Create Safe" data-cy="dataTable">
		<v-form ref="form">
			<v-card class="mb-5" tile>
				<v-layout>
					<v-row class="pl-5 pr-5">
						<v-col cols="12" md="6">
							<v-checkbox v-model="model.active" label="Active"></v-checkbox>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field
								v-model="model.description"
								label="Client Name (Description)"
								:rules="[v => !!v || 'The Client Name (Description) is required']"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field
								label="Select Organisation"
								v-model="searchString"
								append-icon="search"
								@keydown.enter="newSearch"
								@click:append="newSearch"
								clearable
								persistent-hint
								hint="Shop not found? Just click the search icon or hit enter."
								:loading="loadShops"
								autocomplete="false"
								name="orgnisation"
								:rules="[v => !!v || 'Organisation is required']"
							></v-text-field>

							<v-list v-show="showDropdown">
								<div class="text-right">
									<v-btn icon @click="showDropdown = false"><v-icon>close</v-icon></v-btn>
								</div>
								<v-fade-transition group>
									<v-list-item ripple @click="onShopSelected(shop)" v-for="shop in filterByNameOrCode()" :key="shop.id">
										<v-list-item-content>
											<v-list-item-title v-text="`${shop.name} - ${shop.code}`"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-fade-transition>
							</v-list>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="model.safeserial" label="Safe Serial" :rules="[v => !!v || 'The Safe Serial is required']"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-select v-model="model.safetype" :items="safeTypes" label="Safe Type" item-text="description" item-value="id"></v-select>
						</v-col>
						<v-col cols="12" md="6">
							<v-select v-model="model.vendorid" :items="vendors" label="Vendor" item-text="description" item-value="id"></v-select>
						</v-col>
					</v-row>
				</v-layout>
			</v-card>
			<v-card class="mb-5" tile>
				<v-layout>
					<v-row class="pl-5 pr-5">
						<v-col cols="12" md="6">
							<v-select v-model="model.safeOwnerId" :items="safeOwners" label="Safe Owner" item-text="description" item-value="id"></v-select>
						</v-col>
						<v-col cols="12" md="6">
							<v-select
								v-model="model.clientTypeId"
								:items="allClientTypes"
								label="Client Type (Tag)"
								item-text="description"
								item-value="id"
							></v-select>
						</v-col>
						<v-col cols="12" md="6">
							<v-checkbox v-model="model.rental" label="Rental"></v-checkbox>
						</v-col>
						<v-col cols="12" md="6">
							<v-select
								v-if="model.rental"
								v-model="model.rentalAmountId"
								:items="rentalAmounts"
								label="Rental Amount"
								item-text="amount"
								item-value="id"
								:rules="[v => !!v || 'Rental Amount is required']"
							></v-select>
						</v-col>
					</v-row>
				</v-layout>
			</v-card>
			<v-card tile>
				<v-layout>
					<v-row class="pl-5 pr-5">
						<v-col cols="12" md="6">
							<v-select v-model="model.cashcentreId" :items="cashCentres" label="Cash Centre" item-text="description" item-value="id"></v-select>
						</v-col>
						<v-col cols="12" md="6">
							<v-select v-model="model.citid" :items="citProviders" label="CIT" item-text="description" item-value="id"></v-select>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="model.citcode" label="CIT Code"></v-text-field>
						</v-col>
						<v-col cols="12">
							<label>Collection Days</label>
						</v-col>
						<v-col cols="12" sm="6" md="4" v-for="(day, index) in model.collectiondays" :key="index">
							<v-checkbox :label="index.charAt(0).toUpperCase() + index.slice(1)" v-model="model.collectiondays[index]" hide-details></v-checkbox>
						</v-col>
						<v-flex xs12 class="mt-3 text-right mr-2 mb-3">
							<v-btn :block="$vuetify.breakpoint.xsOnly" color="accent" @click="onCreate()">Create</v-btn>
						</v-flex>
					</v-row>
				</v-layout>
			</v-card>
		</v-form>
	</S2SForm>
</template>

<script>
import Vue from "vue";

import { Client, CashCentre, CITProvider, SafesState } from "@/store/modules/safes";

import { Paging, Status } from "@/store/utils";
import { Utils } from "@/utils";

import { LookupsApi } from "@/api/lookups-api";
import { OrganisationsParams } from "@/api/organisation-api";
import { Organisation } from "@/store/organisations";

export default Vue.extend({
	name: "CreateSafe",

	data: function() {
		return {
			model: {},
			safeTypes: [],
			lookupsApi: LookupsApi.fetchLookups,
			vendors: [],
			searchString: "",
			loadShops: false,
			showDropdown: false
		};
	},

	computed: {
		allClients: function() {
			return this.$store.state.safes.allClients;
		},
		cashCentres: function() {
			return this.$store.state.safes.cashCentres;
		},
		citProviders: function() {
			return this.$store.state.safes.citProviders;
		},
		allClientTypes: function() {
			return this.$store.state.safes.allClientTypes;
		},
		rentalAmounts: function() {
			return this.$store.state.safes.rentalAmounts;
		},
		safeOwners: function() {
			return this.$store.state.safes.safeOwners;
		},
		status: function() {
			return this.$store.state.safes.status;
		},
		selectedOrganisation: function() {
			return this.$store.state.organisations.selectedOrganisation;
		},
		organisations: function() {
			return this.$store.state.organisations.organisations;
		},
		pagination: function() {
			return this.$store.state.organisations.pagination;
		}
	},

	watch: {
		organisations: function() {
			this.loadShops = false;
		}
	},

	created: function() {
		this.$store.dispatch("safes/fetchCITProviders");
		this.$store.dispatch("safes/fetchCashCentres");
		this.$store.dispatch("safes/fetchClients");
		this.$store.dispatch("safes/fetchClientTypes");
		this.$store.dispatch("safes/fetchRentalAmounts");
		this.$store.dispatch("safes/fetchSafeOwners");

		this.$store.dispatch("organisations/paginate", { ...this.pagination, itemsPerPage: 10, page: 1 });

		this.lookupsApi("safeTypes").then(res => {
			this.safeTypes = res.data;
		});
		this.lookupsApi("vendors").then(res => {
			this.vendors = res.data;
		});

		this.model.collectiondays = {
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
			sunday: false
		};
	},

	mounted: function() {
		setTimeout(() => {
			for (let client of this.allClients) {
				if (this.$route.params.clientId == client.id.toString()) {
					this.model = client;
					this.$store.dispatch("organisations/fetchOrganisationById", client.organisationid);
				}
			}
		}, 500);
	},

	methods: {
		async onCreate() {
			const form = this.$refs["form"];
			const allOk = await form.validate();
			if (allOk) {
				const success = await this.$store.dispatch("safes/createSafe", this.model);
				if (success) this.$router.push({ name: "safes-client" });
			} else {
				alert("Please correct form");
			}
		},
		filterByNameOrCode() {
			if (this.searchString) {
				return this.organisations.filter(shop => {
					const shopName = shop.name.toLowerCase();
					const shopCode = shop.code.toLowerCase();
					const searchString = this.searchString.toLowerCase();
					return shopName.indexOf(searchString) !== -1 || shopCode.indexOf(searchString) !== -1;
				});
			} else {
				return this.organisations;
			}
		},
		newSearch() {
			this.loadShops = true;
			this.showDropdown = true;
			this.$store.dispatch("organisations/paginate", { ...this.pagination, itemsPerPage: 20, page: 1 });
			this.$store.dispatch("organisations/fetchOrganisations", { pagination: this.pagination, params: { search: this.searchString } });
		},
		onShopSelected(shop) {
			this.searchString = shop.name;
			this.model.organisationid = shop.id;
			this.showDropdown = false;
		}
	}
});
</script>
